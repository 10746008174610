<template>
	<div class="post">
        <div class="info">
            <div class="user">
                <div class="profile-pic"><img :src="this.profilePhoto" alt=""></div>
                <p class="username">
                    {{ this.coupleName }}
                </p>
                <p class="text-additional">
                    <small>{{ this.locationEvent }}</small>
                </p>
            </div>
            <img src="/instgrm/flaticon/menu.png" class="options" alt="">
        </div>
        
        <div class="post-area text-center justify-content-center">
            <img class="heartbeat" id="heartbeat-covid" data-wow-delay="0.2s" src="/instgrm/love-red.png" width="70" style="display:none;">
            <div class="container" v-on:dblclick="doubleLike(this)">
                <div class="row">
                    <img class="img-fluid" src="images/info-2.png"/>
                </div>
            </div>
        </div>


        <div class="post-content">
            <div class="reaction-wrapper">
                <img :src="this.imgLike" class="icon" alt="" v-on:click="oneLike(this)">
                <img src="/instgrm/comment.png" class="icon" alt="">
                <img src="/instgrm/send.png" class="send icon" alt="" style="height:22px;">
                <img src="/instgrm/bookmark.png" class="save icon" alt="">
            </div>
            <p class="likes">{{ this.currentLikes }} likes</p>
            <p class="description"><span>{{ this.coupleName }}</span>
				Protokol Pencegahan Covid-19
				<br>
				Acara ini menerapkan protokol kesehatan pencegahan Covid-19.<br>
				Di mohon untuk mematuhi protokol kesehatan selama berada di area acara pernikahan
            </p>
            <p class="post-time">2 minutes ago</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Covid',
    data() {
		return {
            imgLike: '/instgrm/love.png',
            hasBeenLiked: 0,
            currentLikes: 2032
        }
    },
}
</script>