<template>
    <div id="instastory">

        <!-- Modal Fullscreen -->
        <div class="modal modal-fullscreen text-center justify-content-center" id="modal-fullscreen" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body" style="padding-top:40px;margin-left:-15px;width:108%!important;">
                        <Stories 
                            :stories="itemStories"
                            @allStoriesEnd="closeModal"
                            v-if="showStories"
                        ></Stories>
                    </div>
                </div>
            </div>
        </div>

        <div class="status-wrapper nicescroll-horizontal" style="overflow-x: scroll;">
            <div class="status-card" v-on:click="openModal('item1')">
                <div class="profile-pic"><img src="/photos/feeds/story1.jpg" alt=""></div>
                <p class="username">Invitation</p>
            </div>
            <div class="status-card" v-on:click="openModal('item2')">
                <div class="profile-pic"><img src="/photos/feeds/instastory2.jpg" alt=""></div>
                <p class="username">{{ this.coupleName }}</p>
            </div>
            <div class="status-card" v-on:click="openModal('item3')">
                <div class="profile-pic"><img src="/photos/feeds/instastory3.jpg" alt=""></div>
                <p class="username">Prewed</p>
            </div>
            <div class="status-card" v-on:click="openModal('item4')">
                <div class="profile-pic"><img src="/photos/feeds/instastory1.jpg" alt=""></div>
                <p class="username">Lamaran</p>
            </div>
            <div class="status-card">
                <div class="profile-pic"><img src="" alt=""></div>
                <p class="username">Event</p>
            </div>
        </div>

    </div>
</template>

<script>
import { Stories } from "vue-insta-stories";
export default {
    name: 'Instastory',
    components: { Stories },
    data() {
		return {
            itemStories: '',
            showStories: false,
            items: {
                item1: ["/photos/feeds/story1.jpg", "/photos/feeds/story2.jpg", "/photos/feeds/story3.jpg"],
                item2: ["/photos/feeds/instastory2.jpg"],
                item3: ["/photos/feeds/instastory3.jpg"],
                item4: ["/photos/feeds/instastory1.jpg"],
            }
        }
    },
    methods: {
        openModal: function(index) {
            this.showStories = true;
            this.itemStories = this.items[index]
            $('#modal-fullscreen').modal();
        },
        closeModal: function() {
            this.showStories = false;
            $('#modal-fullscreen').modal('hide');
        }
    }
}
</script>

<style scoped>
    .modal-dialog {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .modal-content {
        height: auto;
        min-height: 100%;
        border: 0 none;
        border-radius: 0;
        box-shadow: none;
    }
</style>