const MyPlugin = {
    install(Vue, options) {
        Vue.prototype.oneLike = function() {
            if (this.hasBeenLiked) {
                this.currentLikes--
                this.imgLike = '/instgrm/love.png'
            }
            else {
                this.currentLikes++
                this.imgLike = '/instgrm/love-red.png'
            }
            this.hasBeenLiked = !this.hasBeenLiked;
        },
        Vue.prototype.doubleLike = function(hasBeenLiked, currentLikes) {
            if (!this.hasBeenLiked) {
                this.currentLikes++
                this.imgLike = '/instgrm/love-red.png'
            }
            this.hasBeenLiked = true;
        },
        Vue.mixin({
            mounted() {
                // Animate heart instagram onclick
                $(".post-area").dblclick(function() {
                    var selector = $(this).find('.heartbeat').attr('id');
                    $('#'+selector).show();
                    $('#'+selector).addClass("wow bounceIn animated");
                    $('#'+selector).attr("style", "visibility: visible; animation-name: bounceIn;");
                    setTimeout(function() {
                        $('#'+selector).removeClass("wow bounceIn animated");
                        $('#'+selector).removeAttr("style");
                        $('#'+selector).hide();
                    }, 1000);
                });
            }
        })
    },
}

export default MyPlugin