<template>
	<div id="home">
        <!-- Navbar -->
        <nav class="navbar-ig">
            <div class="nav-wrapper-ig">
                <img src="/instgrm/logo.png" class="brand-img" alt="">
                <div class="nav-items">
                    <router-link to="/gift">
                        <img src="/instgrm/add.png" class="icon" alt="">
                    </router-link>
                    <router-link to="/story">
                        <img src="/instgrm/love.png" class="icon" alt="">
                    </router-link>
                    <router-link to="/messages">
                        <img src="/instgrm/flaticon/facebook-messenger.png" class="icon" alt="" style="height:34px;">
                    </router-link>
                </div>
            </div>
        </nav>
        <div class="left-col" style="margin-top:50px;">
            <Instastory />
            <Intro />
            <Couple />
            <Event />
            <Covid />
            <Finish />
        </div>
	</div>
</template>

<script>

    import Instastory from './Instastory.vue'
    import Intro from './Feeds/Intro.vue'
    import Couple from './Feeds/Couple.vue'
    import Event from './Feeds/Event.vue'
    import Covid from './Feeds/Covid.vue'
    import Finish from './Feeds/Finish.vue'

    export default {
        name: 'Home',
        data() {
            return {
                urlParam: ''
            }
        },
        components: {
            Instastory,
            Intro,
            Couple,
            Event,
            Covid,
            Finish,
        },
        created() {
            let url = new URL(location.href).searchParams.get('to')
            this.urlParam = url
        },
    }
</script>


<style scoped>
	#start-bg {
		background: linear-gradient(to right top, #f0e4bd, rgba(255, 255, 255, 0.5)), url("/photos/event/background.jpg") no-repeat fixed;
		background-size: cover;
	}
	.modal-body {
		background: linear-gradient(to right top, #f0e4bd, rgba(255, 255, 255, 0.5)), url("/photos/event/yellow.jpg");
		background-size: cover;
	}
</style>