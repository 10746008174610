<template>
	<div id="gallery" class="gallery-box">
		<div class="container-fluid">
			<h3 style="margin-top:5px;padding:15px;font-weight:bold;">
                <router-link to="/">
                    <i class="fa fa-arrow-left" style="margin-left:-10px;padding-right:20px;"></i>
                    {{ this.coupleName }}
                </router-link>
            </h3>
			<div class="row" style="padding:10px 0px 20px 0px;">
				<div class="col-4">
					<div class="status-card" >
						<div class="profile-img"><img src="/photos/rk.jpg" alt=""></div>
					</div>
				</div>
				<div class="col-8 text-center" style="margin-top:25px;line-height:1.4;">
					<div style="width:50px; position:absolute; right:0px; margin-right:190px;">
						<b>8</b> <br>
						<small>Posts</small>
					</div>
					<div style="width:50px; position:absolute; right:0px; margin-right:120px;">
						<b>4,987</b> <br>
						<small>Followers</small>
					</div>
					<div style="width:50px; position:absolute; right:40px;">
						<b>4,224</b> <br>
						<small>Following</small>
					</div>
				</div>
			</div>
			<div class="row">
				<div style="margin:-10px 0px 25px 15px;line-height:1.4;">
					<small>
						<span style="font-weight:bold;">The Wedding of {{ this.coupleName }}</span> <br>
						<span>{{ this.textDate }}</span> <br>
						<span style="color:#636365;" onClick="window.open('https://instagram.com/ondangan.website', '_blank', 'noopener')">@pangeran.rama</span> <br>
						<span style="color:#636365;" onClick="window.open('https://instagram.com/ondangan.website', '_blank', 'noopener')">@permaisuri.shinta</span> <br>
					</small>
				</div>
			</div>
			
			<div class="row">
				<div class="col-12" style="padding:0px;">
					<ul class="nav nav-tabs small justify-content-center text-center" role="tablist">
						<li class="nav-item"><span style="background:none; cursor:pointer;border:none;" class="nav-link active" data-toggle="tab" href="#tab1" role="tab">Photos</span></li>
						<li class="nav-item"><span style="background:none; cursor:pointer;border:none;" class="nav-link" data-toggle="tab" href="#tab2" role="tab">Video</span></li>
					</ul>
					<div class="tab-content py-2">
						<div class="tab-pane active" id="tab1">
							<ul ref="gallery" class="popup-gallery clearfix">
								<li v-for="(image, key) in images" :key="key" class="wow fadeIn">
									<a :href="images[key]">
										<img class="img-fluid" :src="thumbs[key]" alt="single image">
										<span class="overlay"><i class="fa fa-heart-o" aria-hidden="true"></i></span>
									</a>
								</li>
							</ul>
						</div>
						<div class="tab-pane" id="tab2">
							<iframe width="100%" height="400" :src="this.youtube" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'Gallery',
	data() {
		return {
			thumbs: [
				'photos/gallery/thumb (1).jpg',
				'photos/gallery/thumb (2).jpg',
				'photos/gallery/thumb (3).jpg',
				'photos/gallery/thumb (4).jpg',
				'photos/gallery/thumb (5).jpg',
				'photos/gallery/thumb (6).jpg',
				'photos/gallery/thumb (7).jpg',
				'photos/gallery/thumb (8).jpg',
			],
			images: [
				'photos/gallery/thumb (1).jpg',
				'photos/gallery/thumb (2).jpg',
				'photos/gallery/thumb (3).jpg',
				'photos/gallery/thumb (4).jpg',
				'photos/gallery/thumb (5).jpg',
				'photos/gallery/thumb (6).jpg',
				'photos/gallery/thumb (7).jpg',
				'photos/gallery/thumb (8).jpg',
			],
		}
	},
	mounted() {
		let selector = this.$refs.gallery
		$(selector).magnificPopup({
			delegate: 'a',
			type: 'image',
			tLoading: 'Loading image #%curr%...',
			mainClass: 'mfp-img-mobile',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0,1]
			},
			image: {
				tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
			}
		});
	}
}
</script>

<style scoped>
	.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
		font-weight: bold;
	}
</style>