<template>
	<div class="post">
        <div class="info">
            <div class="user">
                <div class="profile-pic"><img :src="this.profilePhoto" alt=""></div>
                <p class="username">
                    {{ this.coupleName }}
                </p>
                <p class="text-additional">
                    <small>{{ this.locationEvent }}</small>
                </p>
            </div>
            <img src="/instgrm/flaticon/menu.png" class="options" alt="">
        </div>
        
        <div class="post-area text-center justify-content-center">
            <img class="heartbeat" id="heartbeat-intro" data-wow-delay="0.2s" src="/instgrm/love-red.png" width="70" style="display:none;">
            <swiper class="swiper" :options="this.swiperOption" v-on:dblclick.native="doubleLike(this)">
                <swiper-slide>
                    <img src="/photos/feeds/story1.jpg" class="post-image" alt="">
                </swiper-slide>
                <swiper-slide>
                    <img src="/photos/feeds/story2.jpg" class="post-image" alt="">
                </swiper-slide>
                <swiper-slide>
                    <img src="/photos/feeds/story3.jpg" class="post-image" alt="">
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>

        <div class="post-content">
            <div class="reaction-wrapper">
                <img :src="this.imgLike" class="icon" alt="" v-on:click="oneLike(this)">
                <img src="/instgrm/comment.png" class="icon" alt="">
                <img src="/instgrm/send.png" class="send icon" alt="" style="height:22px;">
                <img src="/instgrm/bookmark.png" class="save icon" alt="">
            </div>
            <p class="likes">{{ this.currentLikes }} likes</p>
            <p class="description"><span>{{ this.coupleName }}</span>
                Assalamualaikum wr wb
                <br>
                Maha Suci Allah yang telah menciptakan manusia dengan berpasang-pasangan.
                <br> 
                Kami sekeluarga bermaksud mengundang Bapak/Ibu/Saudara/i dalam acara pernikahan kami, yang Insya Allah akan diselenggarakan pada :
                <br><br>
                - Hari : {{ this.textDate }}<br>
                - Pukul : 08.00 s.d. selesai
                <br>
                - Tempat : Ascott Sudirman Jakarta
                <br><br>
                Ungkapan terima kasih yang tulus dari kami apabila Bapak/Ibu/Saudara/i berkenan hadir dan memberikan do’a restu. 
            </p>
            <p class="post-time">2 minutes ago</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Intro',
        data() {
            return {
                imgLike: '/instgrm/love.png',
                hasBeenLiked: 0,
                currentLikes: 2489
            }
        }
    }
</script>