<template>
	<div class="main-timeline-box">
		<div class="container">
			<h3 style="margin-top:5px;padding:15px;font-weight:bold;">
                <router-link to="/">
                    <i class="fa fa-arrow-left" style="margin-left:-10px;padding-right:20px;"></i>
                    Our Story
                </router-link>
            </h3>
			<div class="row timeline-element separline" style="padding-top:20px;">
				<div class="timeline-date-panel col-xs-12 col-md-6 align-left wow slideInLeft">
                    <div class="time-line-date-content">
                        <p class="mbr-timeline-date mbr-fonts-style display-font">
                            March 2012
                        </p>
                    </div>
				</div>
				<span class="iconBackground"></span>
				<div class="col-xs-12 col-md-6 align-left wow slideInRight">
					<div class="timeline-text-content">
						<h4 class="mbr-timeline-title pb-3 mbr-fonts-style display-font">First meet</h4>
						<p class="mbr-timeline-text mbr-fonts-style display-7">
						   Kita bertemu di kantin sekolah saat masih duduk di bangku SMA 
						</p>
					 </div>
				</div>
			</div>
			<div class="row timeline-element reverse separline">
				<div class="timeline-date-panel col-xs-12 col-md-6 align-left wow slideInLeft">
                    <div class="time-line-date-content">
                        <p class="mbr-timeline-date mbr-fonts-style display-font">
                            August 2019
                        </p>
                    </div>
				</div>
				<span class="iconBackground"></span>
				<div class="col-xs-12 col-md-6 align-right wow slideInRight">
					<div class="timeline-text-content">
						<h4 class="mbr-timeline-title pb-3 mbr-fonts-style display-font">First date</h4>
						<p class="mbr-timeline-text mbr-fonts-style display-7">
							Karena kebetulan satu kota, dan udah 7 tahun sejak lulus SMA, Romeo megajak bertemu untuk nostalgia
						</p>
					 </div>
				</div>
			</div>
			<div class="row timeline-element separline">
				<div class="timeline-date-panel col-xs-12 col-md-6 align-left wow slideInLeft">
                    <div class="time-line-date-content">
                        <p class="mbr-timeline-date mbr-fonts-style display-font">
                            12 September 2019
                        </p>
                    </div>
				</div>
				<span class="iconBackground"></span>
				<div class="col-xs-12 col-md-6 align-left wow slideInRight">
					<div class="timeline-text-content">
						<h4 class="mbr-timeline-title pb-3 mbr-fonts-style display-font">Proposal</h4>
						<p class="mbr-timeline-text mbr-fonts-style display-7">
							Cerita di mulai darisini
						</p>
					 </div>
				</div>
			</div>
			<div class="row timeline-element reverse separline">
				<div class="timeline-date-panel col-xs-12 col-md-6 align-left wow slideInLeft">
                    <div class="time-line-date-content">
                        <p class="mbr-timeline-date mbr-fonts-style display-font">
                            8 August 2020
                        </p>
                    </div>
				</div>
				<span class="iconBackground"></span>
				<div class="col-xs-12 col-md-6 align-right wow slideInRight">
					<div class="timeline-text-content">
						<h4 class="mbr-timeline-title pb-3 mbr-fonts-style display-font">Engagement</h4>
						<p class="mbr-timeline-text mbr-fonts-style display-7">
							Romeo, teman SMA ku datang melamar!
						</p>
					 </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'Story',
}
</script>