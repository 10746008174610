<template>
	<div class="post">
        <hr>
		<div class="container wow bounceIn" data-wow-delay="0.3s" style="padding-bottom:50px;">
			<div class="row text-center justify-content-center">
				<img class="img-fluid" src="instgrm/flaticon/check.png" style="width:85px;padding:15px;">
			</div>
            <h4 align="center"><b>You're All Caught Up</b></h4>
            <p align="center">
                &copy; 2021 by <small onClick="window.open('https://ondangan.website', '_blank', 'noopener')">ondangan.website</small>
            </p>
		</div>
    </div>
</template>

<script>
export default {
    name: 'Finish',
}
</script>