import moment from 'moment'
import "moment/locale/id";

const MyPlugin = {
    install(Vue, options) {
        Vue.prototype.profilePhoto = '/photos/rk.jpg';
        Vue.prototype.username = 'ondangan.website';
        Vue.prototype.coupleName = 'Rama & Shinta';
        Vue.prototype.textEvent = 'Wedding of Rama %26 Shinta',
        Vue.prototype.textTime = 'Pukul 11.00 - 16.00',
        Vue.prototype.dateEvent = '20201206T110000Z+07:00/20201206T130000Z+07:00',
        Vue.prototype.locationEvent = 'Ascott Sudirman Jakarta',
        Vue.prototype.urlEvent = 'https://ondangan.website'
        Vue.prototype.maps = 'https://goo.gl/maps/dxNH5cJHdUmn2sGP7'
        Vue.prototype.mapsEmbeded = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.3125946954024!2d106.81784801536983!3d-6.22244906267663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f3fe64120fd3%3A0xe010f4b071227f4f!2sAscott%20Sudirman%20Jakarta!5e0!3m2!1sid!2sid!4v1635423789302!5m2!1sid!2sid'
        Vue.prototype.youtube = 'https://www.youtube.com/embed/LShjbZQtYvM?controls=0'
        
        Vue.mixin({
            data: function () {
                return {
                    textDate: '',
                    eventDate: ''
                }
            },
            created: function () {
                this.textDate = this.format_date('dddd, LL');
                this.eventDate = this.format_date('YYYY/MM/DD hh:mm:ss');
            },
            methods: {
                format_date(param){
                    let date = new Date();
                    date.setDate(date.getDate()+7)
                    return moment.unix(date/1000).format(param);
                },
            }
        });

        Vue.prototype.swiperOption =  {
            slidesPerView: 1,
            spaceBetween: 30,
            loop: false,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
        };
    }
}

export default MyPlugin