<template>
    <div class="post">
        <div class="info">
            <div class="user">
                <div class="profile-pic"><img :src="this.profilePhoto" alt=""></div>
                <p class="username">
                    {{ this.coupleName }}
                </p>
                <p class="text-additional">
                    <small>{{ this.locationEvent }}</small>
                </p>
            </div>
            <img src="/instgrm/flaticon/menu.png" class="options" alt="">
        </div>

        <div class="post-area text-center justify-content-center">
            <img class="heartbeat" id="heartbeat-couple" data-wow-delay="0.2s" src="/instgrm/love-red.png" width="70" style="display: none;">
            <swiper class="swiper" :options="this.swiperOption" v-on:dblclick.native="doubleLike(this)">
                <swiper-slide>
                    <div class="container">
                        <div class="row text-center justify-content-center">
                            <div class="col-lg-12" style="padding-top:100px;">
                                <div class="title-box wow zoomInDown" id="couple">
                                    <img src="/photos/couple/flower-top.png" alt="" width="300px"><br><br>
                                    <h3>
                                        <i><b>Assalamu'alaikum Warahmatullaahi Wabarakaatuh </b></i>
                                    </h3>
                                    <p>Maha Suci Allah yang telah menciptakan makhluk-Nya berpasang-pasangan. Ya Allah semoga ridho-Mu tercurah mengiringi pernikahan kami.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="container">
                        <div class="row text-center justify-content-center">
                            <div class="event-inner">
                                <div class="event-img">
                                    <img class="img-fluid" src="/photos/couple/2.jpg" alt="">
                                </div>
                                <h2>{{ this.resultName[0] }}</h2>
                                <p>Anak ke-2 dari Bapak Fulan dan Ibu Fulanah</p>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="container">
                        <div class="row text-center justify-content-center">
                            <div class="event-inner">
                                <div class="event-img">
                                    <img class="img-fluid" src="/photos/couple/1.jpg" alt="">
                                </div>
                                <h2>{{ this.resultName[2] }}</h2>
                                <p>Anak ke-3 dari Bapak Fulan dan Ibu Fulanah</p>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>

        <div class="post-content">
            <div class="reaction-wrapper">
                <img :src="this.imgLike" class="icon" alt="" v-on:click="oneLike(this)">
                <img src="/instgrm/comment.png" class="icon" alt="">
                <img src="/instgrm/send.png" class="send icon" alt="" style="height:22px;">
                <img src="/instgrm/bookmark.png" class="save icon" alt="">
            </div>
            <p class="likes">{{ this.currentLikes }} likes</p>
            <p class="description"><span>{{ this.coupleName }}</span>
                We are getting married
            </p>
            <p class="post-time">2 minutes ago</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Couple',
    data() {
		return {
            resultName: '',
            imgLike: '/instgrm/love.png',
            hasBeenLiked: 0,
            currentLikes: 2591
        }
    },
    created() {
		this.resultName = this.coupleName.split(" ");
	},
}
</script>