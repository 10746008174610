<template>
	<div id="gift">
		<div class="container">
			<h3 style="margin-top:5px;padding:15px;font-weight:bold;">
                <router-link to="/">
                    <i class="fa fa-arrow-left" style="margin-left:-10px;padding-right:20px;"></i>
                    Wedding Gift
                </router-link>
            </h3>
			<div class="row text-center justify-content-center" style="padding:10px 0px 20px 0px;">
                <div class="col-10" style="padding-bottom:20px;">
                    Doa restu Anda merupakan karunia yang sangat berarti bagi kami. Dan jika memberi adalah ungkapan tanda kasih Anda, Anda dapat memberi kado secara cashless.
                </div>
				<div class="col-lg-6 col-md-6 col-sm-12">
					<div class="event-inner wow bounceInLeft">
						<img src="/images/logo-bni.png" alt="logo-bni" width="120px;">
                        <p class="pt-2">
                            <span id="copy1">9927262652</span>
                            <br>a.n Romeo
                        </p>
                        <button type="button" class="btn btn-danger text-white" @click="copyText('copy1')">
                            Copy Number
                        </button>
					</div>
				</div>
				<div class="col-lg-6 col-md-6 col-sm-12">
					<div class="event-inner wow bounceInRight">
						<img src="/images/logo-bca.png" alt="logo-bca" width="120px;">
                        <p class="pt-2">
                            <span id="copy2">68543332</span>
                            <br>a.n Juliet
                        </p>
						<button type="button" class="btn btn-danger text-white" @click="copyText('copy2')">
                            Copy Number
                        </button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'Gift',
    methods: {
        copyText(id) {
            var copyText = document.getElementById(id);  
            var input = document.createElement("textarea");
            input.value = copyText.textContent;
            document.body.appendChild(input);
            input.select();
            document.execCommand("Copy");
            input.remove();
            this.$toast.default('Copied', {
				position: "bottom",
				dismissible: true
			})
        }
    }
}
</script>