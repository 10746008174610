import Vue from 'vue'
import App from './App.vue'
import Router from './router'
import Global from './plugins/global'
import Plugin from './plugins/helper'

import VueChatScroll from 'vue-chat-scroll'
import VueKeepScrollPosition from 'vue-keep-scroll-position'
import vueScrollBehavior from 'vue-scroll-behavior'
import VueToast from 'vue-toast-notification';
import VueLetterAvatar from 'vue-letter-avatar';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import 'vue-toast-notification/dist/theme-default.css';
import './registerServiceWorker'

Vue.config.productionTip = false
Vue.use(Global);
Vue.use(Plugin);
Vue.use(VueChatScroll);
Vue.use(VueKeepScrollPosition);
Vue.use(vueScrollBehavior, { router: Router })
Vue.use(VueToast);
Vue.use(VueLetterAvatar);
Vue.use(VueAwesomeSwiper);
Vue.component('Swiper');
Vue.component('SwiperSlide');

new Vue({
	'router': Router, 
	'data': {}, 
	'methods': {},
	render: h => h(App),
}).$mount('#app');
