import Vue from 'vue'
import VueRouter from 'vue-router'

// Init view
import Home from '@/components/Home.vue'
import Location from '@/components/Location.vue'
import Gallery from '@/components/Gallery.vue'
import Story from '@/components/Story.vue'
import Gift from '@/components/Gift.vue'
import Messages from '@/components/Messages.vue'

Vue.use(VueRouter);

const router = new VueRouter({
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    mode: 'history',

    routes: [
        {
            path: '/location',
            name: 'Location',
            component: Location
        },
        {
            path: '/gallery',
            name: 'Gallery',
            component: Gallery
        },
        {
            path: '/story',
            name: 'Story',
            component: Story
        },
        {
            path: '/gift',
            name: 'Gift',
            component: Gift
        },
        {
            path: '/messages',
            name: 'Messages',
            component: Messages
        },
        {
            path: '/',
            name: 'default',
            component: Home
        }
    ]
})

export default router